import { defineStore } from 'pinia'
import useIsLoadingStore from '@uicommon/stores/useIsLoadingStore'
import { fetchHostCriteria } from '@uicommon/services/hbs'
import { presentHostedUrl } from 'src/utils'

const HHA_ROLE_ID =  'core-app'

const makeUseHhaStore = ({ useHoloStore }) => {
  
  const callZome = args =>
    useHoloStore().callZome({
      ...args,
      role_name: HHA_ROLE_ID,
      zome_name: 'hha'
    })

  const callZomeWithArgs = async (fn_name, payload) => {
    const callZomeArgs = {
      fn_name,
      payload: payload,
    }

    return await callZome(callZomeArgs)
  }

  return defineStore('hha', {
    state: () => ({
      happs: [],
      holoport_infos: {}, // keyed by host_pub_key
    }),
    getters: {
      getMyHappsIsLoading: _ => 
        useIsLoadingStore().isLoading({ zome_name: 'hha', fn_name: 'get_my_happs' }),
      pauseIsLoading: _ => 
        useIsLoadingStore().isLoading({ zome_name: 'hha', fn_name: 'publisher_pause_happ' }),
      unpauseIsLoading: _ => 
        useIsLoadingStore().isLoading({ zome_name: 'hha', fn_name: 'publisher_unpause_happ' }),
      getHappName: state => id => state.happs.find(happ => happ.id === id)?.name,
      holoport_pricing_and_jurisdictions: state => // this just turns holoport_infos into an array
        Object.keys(state.holoport_infos).map(host_pub_key => ({
          ...state.holoport_infos[host_pub_key],
          host_pub_key
        })),
      addJurisdictionInfoToInvoice: state => invoice => {
        const jurisdiction = state.holoport_infos[invoice.counterparty]?.jurisdiction || 'unknown'

        const happ = state.happs.find(happ => happ.id === invoice.happId)

        const isJurisdictionExcluded = happ && (happ.exclude_jurisdictions
          ? happ.jurisdictions.includes(jurisdiction)
          : !happ.jurisdictions.includes(jurisdiction))

        return {
          ...invoice,
          jurisdiction,
          isJurisdictionExcluded,
        }
      }
    },
    actions: {
      happ(id) {
         return this.happs?.find((happ) => happ.id === id)
      },
      async loadHapps() {
        this.happs = await callZomeWithArgs('get_my_happs', null)
      },
      async createDraft(payload) {
        const result = await callZomeWithArgs('create_draft', payload)

        this.happs = this.happs.concat(result)

        return result
      },
      async updateDraft(payload) {
        const updatedDraft = await callZomeWithArgs('update_draft', payload)

        this.happs = this.happs.map((happ) =>
          happ.id === payload.id ? updatedDraft : happ
        )

        return updatedDraft
      },
      isUrlUnique(url, happId) {
        return !this.happs.some((happ) => happ.id != happId && happ.hosted_urls && happ.hosted_urls.length > 0 && happ.hosted_urls[0] === presentHostedUrl(url) )
      },
      async deployHapp(draftId) {
        const deployedHapp = await callZomeWithArgs('publish_happ', draftId) // Changed the verb publish --> deploy but not modifying the zome call names

        this.happs = this.happs.map((happ) =>
          happ.id === draftId ? deployedHapp : happ
        )

        return deployedHapp
      },
      async createAndDeployDraft(payload) {
        const draft = await this.createDraft(payload)
        return this.deployHapp(draft.id)
      },
      async updateHapp(payload) {
        const updatedHapp = await callZomeWithArgs('update_happ', payload)

        this.happs = this.happs.map((happ) =>
          happ.id === payload.id ? updatedHapp : happ
        )        

        return updatedHapp          
      },
      async updatePublisherJurisdiction(payload) {
        await callZomeWithArgs('set_publisher_jurisdiction', payload)
      },
      async pauseHapp(happId) {
        await callZomeWithArgs('publisher_pause_happ', happId)

        this.happs = this.happs.map((happ) =>
          happ.id === happId ? {...happ, is_paused: true } : happ
        )
      },
      async unpauseHapp(happId) {
        await callZomeWithArgs('publisher_unpause_happ', happId)

        this.happs = this.happs.map((happ) =>
          happ.id === happId ? {...happ, is_paused: false } : happ
        )
      },
      async removeHapp(happId) {
        await callZomeWithArgs('publisher_remove_happ', happId)

        this.happs = this.happs.filter(happ => happ.id !== happId)
      },
      async fetchHoloportPricingAndJurisdictions(environment, hbsServicePort) {

        // 1. Get a list of all hApps and find hApp where the special installed hApp id set
        const all_hApps = await callZomeWithArgs('get_happs', null)
        const hApp_with_special_installed_app_id = all_hApps.find(
          hApp => hApp.special_installed_app_id !== null &&
          hApp.special_installed_app_id.indexOf('core-app') !== -1
        )

        if( !hApp_with_special_installed_app_id ) {
          console.log(`⚠️ Could not fetch holoport pricing information: no core-app found`)
          this.holoport_pricing_and_jurisdictions = []
          return
        }

        // 2. Get all hosts from HHA using the hApp Id of the hApp w/ core-app special installed hApp id
        const all_hosts = await callZomeWithArgs('get_hosts', hApp_with_special_installed_app_id.id)

        // 3. Fetch host criteria from HBS using a list of the hosts' public key
        const host_public_keys = all_hosts.map(host => host.host_pub_key)
        let host_infos = [] 
        
        try {
          host_infos = await fetchHostCriteria(host_public_keys, environment, hbsServicePort)
        }
        catch {
          console.log(`⚠️ Could not fetch host criteria from HBS`)
        }
        
        // 4. Combine list of all hosts with their pricing and jurisdictions        
        this.holoport_infos = all_hosts.reduce((holoport_infos, host) => {
          const hbsHostInfo = host_infos.find(hbsHostInfo => hbsHostInfo.pubkey === host.host_pub_key)
          console.log(`🐮 pubkey: ${host.host_pub_key} cpu:${host.preferences.price_compute} storage: ${host.preferences.price_storage} xfer: ${host.preferences.price_bandwidth} jurisdiction: ${hbsHostInfo?.jurisdiction}`)
          return {
            ...holoport_infos,
            [host.host_pub_key]: {
              price_compute: host.preferences.price_compute,
              price_storage: host.preferences.price_storage,
              price_bandwidth: host.preferences.price_bandwidth,
              kyc_level: hbsHostInfo ? hbsHostInfo.kycLevel : null,
              jurisdiction: hbsHostInfo ? hbsHostInfo?.jurisdiction : null
            }
          }
        }, {})
      }
    }    
  })
}

export default makeUseHhaStore
